<!--
 * @version: 1.0
 * @Date: 2023-04-17 15:44:59
 * @LastEditors: chenxu chenxu@libawall.cn
-->
<template>
  <section>
    <div class="form-style">
      <a-input
        placeholder="请输入流程名称"
        v-model:value="search"
        style="width:365px"
        allowClear
        @keyup.enter="searchList"
      >
        <template #prefix>
          <SearchOutlined style="color: rgba(0, 0, 0, 0.25)" />
        </template>
      </a-input>
      <a-button @click="searchList" type="primary" class="form-btn"
        >查询</a-button
      >
    </div>
    <div class="business">
      <div class="bus-header">
        <div class="bus-header-left">用印流程列表</div>
        <div class="bus-header-right">
          <a-button
            @click="addProcess"
            type="primary"
            class="right-btn"
            v-if="ableAdd"
          >
            <PlusOutlined />
            添加用印流程
          </a-button>
        </div>
      </div>
      <a-table
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :rowKey="
          (record, index) => {
            return index
          }
        "
        class="table"
      >
        <!-- 印章名称 -->
        <template #name="{ record, text }">
          <div class="name">
            <img
              v-if="record.type === 1"
              src="@/assets/images/business/draft.png"
              class="draft"
            />
            <img
              :src="record.icon"
              alt=""
              class="name-icon"
              :style="{ marginLeft: record.type === 1 ? '-32px' : '0px' }"
            />
            <div class="name-right">
              <div v-if="(text && text.length <= 14) || !text">
                {{ text || '-' }}
              </div>
              <a-tooltip
                placement="bottomLeft"
                v-else
                overlayClassName="lang-name-pop"
              >
                <template #title>
                  <span>{{ text || '-' }}</span>
                </template>
                <div class="lang-name">{{ text }}</div>
              </a-tooltip>
              <!-- 说明 -->
              <div
                class="name-remark"
                v-if="
                  (record.remark && record.remark.length <= 14) ||
                    !record.remark
                "
              >
                {{ record.remark }}
              </div>
              <a-tooltip
                placement="bottomLeft"
                v-else
                overlayClassName="lang-name-pop"
              >
                <template #title>
                  <span>{{ record.remark || '-' }}</span>
                </template>
                <div class="lang-name name-remark">{{ record.remark }}</div>
              </a-tooltip>
            </div>
          </div>
        </template>
        <!-- 印章列表 -->
        <template #sealList="{ record, text }">
          <!-- 所有印章展示（所有印章）字样 -->
          <div v-if="text.length <= 6 || record.sealRange == 1">
            {{ record.sealRange == 1 ? '所有印章' : text || '-' }}
          </div>
          <a-tooltip
            placement="bottomLeft"
            v-else
            overlayClassName="lang-name-pop"
          >
            <template #title>
              <span>{{ text }}</span>
            </template>
            <div class="lang">{{ text }}</div>
          </a-tooltip>
        </template>
        <!-- 适用范围 -->
        <template #scopeList="{ record, text }">
          <div class="all" v-if="record.scopeType === 1">所有人</div>
          <template v-else>
            <div v-if="text.length <= 6">
              {{ text || '-' }}
            </div>
            <a-tooltip
              placement="bottomLeft"
              v-else
              overlayClassName="lang-name-pop"
            >
              <template #title>
                <span>{{ text }}</span>
              </template>
              <div class="lang">{{ text }}</div>
            </a-tooltip>
          </template>
        </template>
        <!-- 流程名称负责人 -->
        <template #custodianList="{ text }">
          <div v-if="text.length <= 6">
            {{ text || '-' }}
          </div>
          <a-tooltip
            placement="bottomLeft"
            v-else
            overlayClassName="lang-name-pop"
          >
            <template #title>
              <span> {{ text }}</span>
            </template>
            <div class="lang">{{ text }}</div>
          </a-tooltip>
        </template>
        <!-- 操作 -->
        <template #action="{ record }" v-if="ableEdit || ableDelete">
          <div class="status" v-if="record.type !== 1">
            <div
              v-if="ableEdit"
              @click="changePro(record)"
              class="enable"
              :style="{ color: record.status == 1 ? '#0A7BFF' : '#1890FF' }"
            >
              {{ record.status === 1 ? '禁用' : '启用' }}
            </div>
            <a-dropdown placement="bottomCenter" v-if="ableEdit || ableDelete">
              <div @click.prevent class="more">更多<DownOutlined /></div>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="editPro(record)" v-if="ableEdit">
                    <div>编辑</div>
                  </a-menu-item>
                  <a-menu-item @click="copyPro(record)" v-if="ableEdit">
                    <div>复制</div>
                  </a-menu-item>
                  <a-menu-item @click="deletePro(record)" v-if="ableDelete">
                    <div>删除</div>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <!-- 草稿 -->
          <div class="type" v-else>
            <span class="enable" @click="editPro(record)" v-if="ableEdit"
              >编辑</span
            >
            <a-dropdown placement="bottomCenter" v-if="ableEdit || ableDelete">
              <div @click.prevent class="more">更多<DownOutlined /></div>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="copyPro(record)" v-if="ableEdit">
                    <div>复制</div>
                  </a-menu-item>
                  <a-menu-item @click="deletePro(record)" v-if="ableDelete">
                    <div>删除</div>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </a-table>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, createVNode } from 'vue'
import { paginationParams } from '@/utils/constData'
import { DownOutlined, ExclamationOutlined } from '@ant-design/icons-vue'
import { processList, delProcess, editType } from '@/apis/process'
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { getEnums } from '@/utils/utils'
import { useStore } from 'vuex'
import { permissionCheck } from '@/apis/sealManage.js'

// const searchForm = ref({}) // 搜索框
const ableEdit = ref(true)
const ableAdd = ref(true)
const ableDelete = ref(true)
const router = useRouter()
const search = ref('') // 搜索名称
const columns = ref([])
const dataSource = ref([]) // 表格数据
const pagination = ref({ ...paginationParams })
const loading = ref(false)
const timeSort = ref('')
const iconList = ref([
  { icon: require('@/assets/images/business/icon_1.png'), key: 'ICON_1' },
  { icon: require('@/assets/images/business/icon_2.png'), key: 'ICON_2' },
  { icon: require('@/assets/images/business/icon_3.png'), key: 'ICON_3' },
  { icon: require('@/assets/images/business/icon_4.png'), key: 'ICON_4' },
  { icon: require('@/assets/images/business/icon_5.png'), key: 'ICON_5' },
  { icon: require('@/assets/images/business/icon_6.png'), key: 'ICON_6' },
  { icon: require('@/assets/images/business/icon_7.png'), key: 'ICON_7' },
  { icon: require('@/assets/images/business/icon_8.png'), key: 'ICON_8' },
  { icon: require('@/assets/images/business/icon_9.png'), key: 'ICON_9' },
  { icon: require('@/assets/images/business/icon_10.png'), key: 'ICON_10' },
  { icon: require('@/assets/images/business/icon_11.png'), key: 'ICON_11' },
  { icon: require('@/assets/images/business/icon_12.png'), key: 'ICON_12' }
])
const searchObj = ref({
  pageIndex: pagination.value.current,
  pageSize: pagination.value.pageSize
})

const store = useStore()

onMounted(() => {
  getList(searchObj.value)
  getPermissionCheck()
})

const getList = () => {
  loading.value = true
  // 获取列表
  const params = {
    pageIndex: pagination.value.current,
    pageSize: pagination.value.pageSize,
    search: search.value,
    orderByUpdateTime: timeSort.value
  }
  processList(params).then((res) => {
    console.log(res)
    dataSource.value = res.data
    dataSource.value.forEach((item) => {
      const iconL = iconList.value.filter(
        (el) => item.icon === el.icon || item.icon === el.key
      )
      item.icon =
        iconL[0]?.icon ?? require('@/assets/images/business/default-icon.png')
      const scope = []
      const seal = []
      const custodian = []
      item.scopeList?.forEach((value) => {
        scope.push(value.dataName)
      })
      item.sealList?.forEach((value) => {
        seal.push(value.sealName)
      })
      item.custodianList?.forEach((value) => {
        custodian.push(value.staffName)
      })
      item.scopeList = scope.join('、')
      item.sealList = seal.join('、')
      item.custodianList = custodian.join('、')
    })
    pagination.value.total = res.totalItem
    loading.value = false
  })
}

// 搜索框查询
const searchList = () => {
  console.log('搜索', search.value)
  pagination.value.current = 1
  getList()
}

// 添加流程名称
const addProcess = () => {
  // console.log('跳转页面')
  store.commit('resetProcessData', { type: 1 })
  router.push('/businessAdd')
}

// 切换页数
const handleTableChange = ({ current, pageSize }, filters, { order }) => {
  // console.log(filters, { order });
  timeSort.value = ''
  if (order == 'ascend') {
    timeSort.value = 1
  }
  pagination.value.current = current
  pagination.value.pageSize = pageSize
  dataSource.value = []
  getList()
}

const changePro = (record) => {
  console.log('启用/停用', record)
  if (record.status === 2) {
    editType({
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo'))
        .result.enterpriseId,
      status: record.status === 1 ? '2' : '1',
      processId: record.processId
    }).then((res) => {
      console.log(res)
      if (res.success) {
        cmsNotice('success', '操作成功')
        getList()
      }
    })
    return
  }
  Modal.confirm({
    title: '是否禁用！',
    width: '400px',
    content: '请确认是否禁用，禁用后对已发起的业务不影响',
    centered: true,
    // confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      // 确认返回
      editType({
        enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo'))
          .result.enterpriseId,
        status: record.status === 1 ? '2' : '1',
        processId: record.processId
      }).then((res) => {
        console.log(res)
        if (res.success) {
          cmsNotice('success', '操作成功')
          getList()
        }
      })
    }
  })
}

const editPro = (record) => {
  console.log('编辑', record)
  store.commit('resetProcessData', { type: 2 })
  router.push({
    path: '/businessAdd',
    query: { processId: record.processId, isDraft: record.type }
  })
}

const copyPro = (record) => {
  console.log('复制', record)
  store.commit('resetProcessData', { type: 2 })
  router.push({
    path: '/businessAdd',
    query: { processId: record.processId, isDraft: record.type, isCopy: true }
  })
}

const deletePro = (record) => {
  console.log('删除', record)
  Modal.confirm({
    title: '是否删除！',
    width: '400px',
    content: '删除后无法恢复，是否删除？',
    centered: true,
    // confirmLoading: loading.value,
    icon: createVNode(ExclamationOutlined),
    onOk: () => {
      // 确认返回
      delProcess({
        enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo'))
          .result.enterpriseId,
        processId: record.processId
      }).then((res) => {
        console.log(res)
        if (res.success) {
          cmsNotice('success', '删除成功')
          const { current, pageSize, total } = pagination.value
          total % (current * pageSize - pageSize) === 1 &&
            (pagination.value.current -= 1)
          getList()
        }
      })
    }
  })
}

// 获取功能权限
const getPermissionCheck = async () => {
  await permissionCheck({
    code: 'PC_APPROVAL_PROCESS_MENU_UPDATE'
  })
    .then((res) => {
      console.log('是否有编辑权限', res)
      ableEdit.value = res.data
    })
    .catch((err) => {})
  await permissionCheck({
    code: 'PC_APPROVAL_PROCESS_MENU_DEL'
  })
    .then((res) => {
      console.log('是否有删除权限', res)
      ableDelete.value = res.data
    })
    .catch((err) => {})
  await permissionCheck({
    code: 'PC_APPROVAL_PROCESS_MENU_ADD'
  })
    .then((res) => {
      console.log('是否有添加权限', res)
      ableAdd.value = res.data
    })
    .catch((err) => {})
  console.log('操作是否显示', ableEdit.value, ableDelete.value)
  columns.value = [
    {
      title: '用印流程名称',
      dataIndex: 'name',
      slots: { customRender: 'name' }
    },
    {
      title: '业务类型',
      dataIndex: 'processType',
      key: 'processType',
      width: 100,
      className: 'table-td',
      customRender: ({ text }) => getEnums('PROCESS_TYPE', text)?.desc || '--'
    },
    {
      title: '关联印章',
      dataIndex: 'sealList',
      slots: { customRender: 'sealList' },
      className: 'table-td'
    },
    {
      title: '适用范围',
      dataIndex: 'scopeList',
      slots: { customRender: 'scopeList' },
      className: 'table-td'
    },
    {
      title: '流程管理员',
      dataIndex: 'custodianList',
      slots: { customRender: 'custodianList' },
      className: 'table-td'
    },
    {
      title: '最后更新时间',
      dataIndex: 'updateTime',
      sorter: true,
      key: 'updateTime',
      className: 'table-td'
    }
  ]
  if (ableEdit.value || ableDelete.value) {
    columns.value.push({
      title: '操作',
      dataIndex: 'action',
      slots: { customRender: 'action' },
      className: 'table-td'
    })
  }
}
</script>

<style scoped lang="less">
.form-style {
  display: flex;
  padding: 16px;
  margin: 24px;
  .form-btn {
    margin-left: 8px;
    border-radius: 2px;
  }
}
.business {
  padding: 16px;
  background-color: #fff;
  color: #000;
  margin: 24px;
  .bus-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    vertical-align: middle;
    .right-btn {
      border-radius: 2px;
    }
  }
  .table {
    .draft {
      // display: inline-block;
      position: relative;
      width: 36px;
      height: 36px;
      top: -16px;
      left: -16px;
    }
    .name {
      display: flex;
      position: relative;
      // position: relative;
      .name-icon {
        width: 44px;
        height: 44px;
      }
      .name-right {
        margin-left: 12px;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        margin: auto 12px;
        .lang-name {
          max-width: 220px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .name-remark {
          color: #999999;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    .lang {
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .enable {
      margin-right: 12px;
    }
    .more,
    .enable,
    .type {
      cursor: pointer;
      display: inline-block;
      color: #1890ff;
    }
  }
}
</style>
<style lang="scss">
.lang-name-pop {
  .ant-popover-inner-content {
    max-width: 300px;
  }
}
.table-td {
  display: table-cell;
  vertical-align: middle;
}
</style>
